/* ************************************** */
/* Mandatory CSS required for ng-sortable */
/* ************************************** */

.as-sortable-item, .as-sortable-placeholder {
    display: block;
}

.as-sortable-item {
    -ms-touch-action: none;
    touch-action: none;
    /* to disable context menu on iOS devices */
    -webkit-touch-callout: none;
}

.as-sortable-item-handle {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    height:10px;
    width:100%;    
}

.as-sortable-placeholder {
}

.as-sortable-drag {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
}

.as-sortable-hidden {
    display: none !important;
}

.as-sortable-un-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* ******************************************* */
/* Optional CSS, default style for ng-sortable */
/* ******************************************* */

.as-sortable-item, .as-sortable-placeholder {
    min-height: 20px;
    margin: 1px 2px 5px 1px;
    border-radius: 10px;
}

.as-sortable-item {
    border: solid 1px darkgrey;
    background-color: #ffffff;
}

.as-sortable-item-handle {
    min-height: 30px;
    text-align: center;
    background: gray;
    color: white;
    font-size: 22px;
    border-radius: 10px;        
}

.as-sortable-placeholder {
    border: 1px dashed darkgrey;
    box-sizing: border-box;
    background-color: #dbdbdb;
    
}

.as-sortable-drag {
    opacity: .8;
}

.as-sortable-dragging {
    border-style: none !important;

    &, & .item {
         height: 50px !important;
    }

}

.as-sortable-hidden {
}