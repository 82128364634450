.login-form-container {
    width: 500px;
    margin: 0 auto;

    @media (max-width: $screen-xs-max) {
        width: 100%;

        .form-control {
            margin-bottom: 1em;
        }

        .login-form {
            margin-top: 0;
        }
    }

    .login-footer {
        padding: 1em 2em;

        p {
            margin: 0;
        }
    }

    .message-box {
        margin-top: 2em;
    }

    .img-responsive {
        max-height: 90px;
    }
}

.login-form {
    border: $login-border;
    border-radius: $login-border-radius;
    margin: $login-top-margin auto 0;
    margin-top: $login-top-margin-alt;
    background: $login-bg;
    box-shadow: $login-box-shadow;

    .row {
        margin: 0; // override the margin
    }

    .branding {
        border-bottom: $login-branding-bottom-border;
        background: $login-branding-bg;

        p {
            font-size: 1em;
        }

        @media (max-width: $screen-xs-max) {
            p {
                font-size: .75em;
            }
        }

        .logo, .product {
            padding: 2em;
        }

        .product {
            text-align: right;
            color: $login-branding-color;

            p {
                margin: 0;
            }
        }
    }

    .account-container {
        padding: 3em 1em;
        background: #00567C;

        .account {}

        .login {
            margin-top: 1.5em;
        }
    }

    .signup-container{
        padding: 1em;

        .signup-button {
            margin-top :1em; 
            margin-bottom :1em; 
        }
    }

    .signup-text {
        text-align: center;
        color: white;
        padding: 1em;
    }
}
