.brand-bar {
    z-index: 1;
    height: 90px;
    max-height: $brand-bar-height;
    background: #fff;
    clear: both;
    display: block;
    width: 100%;
    padding: 0 1em;
    border-bottom: 15px solid $brand-primary;

    @media(max-width: $screen-xs-max) {
        border-bottom-width: 1px;
    }


    &.admin{
        position: fixed;
    }

    .logo {
        padding: $logo-padding;

        img {
            // max-height: 60px;
            max-width: 260px;
        }

        @media (max-width: $screen-xs-max) {
            text-align: center;
            img {
                margin: 0 auto .5em;
                // height: 50px;
                width: 200px;
            }
        }
    }

    .product {
        display: inline-block;
        font-size: 1.25em;
        color: #999;
        background: #fff;
        text-align: left;
        margin-left: 2em;

        @media (max-width: $screen-xs-max) {
            text-align: center;
            margin: 0;
            margin-left: -1em;
            margin-right: -1em;
            display: block;
            clear: both;
            font-size: 1em;
        }
    }

    .utility {
        .btn {
            margin: 1em;
            padding: .5em 2em;
        }

        @media(max-width: $screen-xs-max) {
            position: absolute;
            right: 0;
        }

        @media(max-width: $screen-sm-max) {
            width: 100% !important;
            position: absolute;
            right: 1em;
            top: 0;

        }
    }
}

.navbar-header {
    width: 100%;
}

.navbar {
    margin-bottom: 0;

    &.navbar-default {
        border: 0;
    }

    &.navbar-side{
        height: 100%;
        margin-top:$brand-bar-height;
        width: $side-bar-width;
        position: fixed;
        overflow-x:hidden;
        background: #3976b1;
    }

    .navbar-nav {
        width: 100%;

        li {
            min-width: 20%;

            @media (max-width: $screen-sm-max) {
                min-width: 15%;
            }      
                      
            a {
                text-align: center;
                //text-transform: uppercase;
                letter-spacing: .5px;
                font-size: 1em;
                cursor: pointer;
                border-bottom: 5px solid $brand-secondary;
                max-height: 50px;
                padding: 10px 15px;
                

                &:hover {
                    border-bottom: 5px solid lighten($brand-primary, 50);
                }

                &.active {
                    border-bottom: 5px solid $brand-primary;

                    &:hover {
                        border-bottom: 5px solid #fff;
                    }
                }

                @media (min-width: $screen-md-min) {
                    font-size: 1.15em;
                }

                @media (max-width: $screen-sm-max) {
                    min-height: 97px;
                    padding-top: 24px;
                    padding-bottom: 24px;
                }
            }        
        }
    }



    @media (max-width: $screen-xs-max) {
        .container,
        .container-fluid {
            padding: 0;
            margin: 0;
        }

        .navbar-header {
            margin: 0;
            padding: 0;
        }

        .navbar-nav {
            padding: 0;
            margin: 0;

            li {
                width: 100%;
                display: block;

                a {
                    border-bottom: 1px solid darken($brand-secondary, 10%);

                    &.active {
                        border-bottom: 1px solid darken($brand-secondary, 10%);
                        background: #fff;
                        color: #333;
                    }

                    &:hover,
                    &.active:hover {
                        border-bottom: 1px solid darken($brand-secondary, 10%);
                        background: darken($brand-secondary, 5%);
                        color: #fff;
                    }
                }
            }
        }
    }
}

.navbar {
    .container,
    .container-fluid {
        text-align: center;
        &.collapse {
            @media (min-width: $screen-sm-min) {
                display: block;
            }
        }
    }
}

.navbar-toggle {
    &:focus {
        background: #fff;
    }

    &.active {
        background: $brand-secondary;

        &:focus {
            background: $brand-secondary;
        }

        &:hover {
            background: $brand-primary;
        }

        border: 1px solid $brand-primary;

        .icon-bar {
            background: #fff;
        }
    }
}
