.btn-icon {
    background: none;
    transition: background-color 300ms;
    border: none;
    font-size: 1em;

    &.large-fixed {
        $size: 50px;
        width: $size;
        height: $size;
    }

    &.small-fixed {
        $size: 30px;
        width: $size;
        height: $size;
        padding: 0;
    }

    &.btn-default,
    &.btn-primary,
    &.btn-danger,
    &.btn-warning,
    &.btn-success,
    &.btn-info {
        &:hover,
        &:focus {
            color: #fff;
        }

        &:focus {
            outline: none;
        }
    }

    &.btn-default {
        color: #333;
    }

    &.btn-primary {
        color: $brand-primary;
    }

    &.btn-success {
        color: $brand-success;
    }

    &.btn-danger {
        color: $brand-danger;
    }

    &.btn-warning {
        color: $brand-warning;
    }

    &.btn-info {
        color: $brand-info;
    }
}


.outline {
    &.btn-success {
        &[disabled="disabled"] {
            background: none !important;
            border-color: $brand-success;
            color: $brand-success;
        }
    }
}
