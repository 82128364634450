.widgets {
    .widget-body {
        border: 1px solid #ccc;
        border-radius: 5px;
        height: 420px;
        overflow: hidden;
        overflow-y: auto;
        margin-bottom: 2em;
    }

    .widget-title {
        color: #000;
        border: 0;
        font-weight: normal;
        text-align: center;
        margin-top: .5em;
    }
}
