// scss-lint:disable ColorVariable
// Note: The order of color palette is light to dark to maintain semantics of higher values being darker
$grey:  #e7e7e7,
        #d0d2d8,
        #a2a7b6,
        #7f8492,
        #5e626e;

$blue:  #dceefa,
        #aed7f2,
        #77bfee,
        #48a5e1,
        #2980b9;

$green: #cef2da,
        #9ee1b4,
        #6ad88f,
        #3ac368,
        #199643;

$red:   #fbdedf,
        #f3a0a1,
        #ef5a5b,
        #d23435,
        #ab2122;

$yellow:  #fffde7,
          #fff59d,
          #ffee58,
          #fdd835,
          #f9a825;

// scss-lint:enable ColorVariable
@for $i from 1 through 5 {
  .color-grey-#{$i} { color: nth($grey, $i) !important; }
  .color-blue-#{$i} { color: nth($blue, $i) !important; }
  .color-green-#{$i} { color: nth($green, $i) !important; }
  .color-red-#{$i} { color: nth($red, $i) !important; }
  .color-yellow-#{$i} { color: nth($yellow, $i) !important; }
}
