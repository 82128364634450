
.filter-icon {
    color: nth($blue, 5);
    font-size: .75em;

    &.right {
        float: right;
    }

    &.left {
        float: left;
        margin-right: .25em;
    }
}

.filter-header {
    cursor: pointer;

    &:hover {
        background: #efefef;
    }
}

.header-date {
    // font-size: 5em;
}

.flush-table {
    @media (min-width: $screen-sm-min) {
        margin-left: -15px;
        margin-right: -15px;
        padding: 1px;

        .table {
            th:first-child,
            td:first-child {
                padding-left: 1em;
            }

            th:last-child,
            td:last-child {
                padding-right: 1em;
            }
        }
    }
}
