.recs {
    .table {
        .header-title {
            min-width: 120px;
        }

        .header-reference_no {
            width: 120px;
            min-width: 120px;
        }

        .header-due_date {
            width: 200px;
            min-width: 200px;
        }

        .header-description,
        .body-description {
            width: 50%;
            min-width: 300px;
        }

        .body-description {
            white-space: pre-line;
        }

        .body-reference_number {
            width: 145px;
        }

        td{
            min-width: 200px;
            max-width: 200px;
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.rec-form {
    margin-bottom: 3em;

    .btn-toolbar {
        margin-top: 1.25em;
    }

.rec-comment{
    background-color: #fff4e1;
    border-radius: 15px;
}

    .control-label{
        // text-align: left;
        // font-weight: normal;

        @media (max-width: $screen-xs-max) {
            border-bottom: 1px solid #ccc;
            display: block;
            margin: 1em;
            padding: 0 0 .5em 0;
        }
    }

    .form-group {
        p {
            font-size: 1em;
            margin-top: .5em;
        }

        @media (min-width: $screen-sm-min) {
            margin-right: 0;
            margin-left: 0;
        }

        border-bottom: 1px solid #efefef;

        &:last-child {
            border: none;
        }
    }
}

.photo-listing {
    .photo-container {
        overflow: hidden;
        display: inline-block;
        position: relative;
        margin-right: 10px;

        &:first-child {
            margin-left: 0;
        }

        .remove-button {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background: #fff;
            padding: 0.25em;
            border-radius: 0 0 0 7px;
            cursor:pointer; 
        }        
    }

    .photo {
        $size: 100px;
        width: $size;
        height: $size;
        overflow: hidden;

        .img {
            width: auto;
            height: 200%;
            margin-left: -25%;
            margin-top: -25%;
        }
    }
}

.rec-submit {
    margin-top: 2em;
}

.rec-field {
    text-align: left !important;
}
