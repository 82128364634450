.modal-open {
    padding: 0 !important;
}

.modal-body {
}

.modal-body-loginLog{
    max-height: 600px;
    overflow-y: scroll;
    width: 100%;
}


.files-modal {
    margin-top: 5em;

    @media (max-width: $screen-xs-max) {
        margin-top: 0;
    }

    .modal-content {
        // box-shadow: none;
    }
}

.legal-modal {
    .modal-dialog {
        height: 90%;        
    }

    .modal-content {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }
}

 .lightbox-modal {
 }
