.img-responsive {
    &.center {
        margin: 0 auto;
    }
}

@for $i from 1 through 5 {
    .padding-bottom-#{$i}em {
        padding-bottom: #{$i}em;
    }

    .padding-top-#{$i}em {
        padding-top: #{$i}em;
    }

    .padding-left-#{$i}em {
        padding-left: #{$i}em;
    }

    .padding-right-#{$i}em {
        padding-right: #{$i}em;
    }

    .margin-bottom-#{$i}em {
        margin-bottom: #{$i}em;
    }

    .margin-top-#{$i}em {
        margin-top: #{$i}em;
    }

    .margin-left-#{$i}em {
        margin-left: #{$i}em;
    }

    .margin-right-#{$i}em {
        margin-right: #{$i}em;
    }
}

@for $i from 1 through 50 {
    .padding-bottom-#{$i}px {
        padding-bottom: #{$i}px;
    }

    .padding-top-#{$i}px {
        padding-top: #{$i}px;
    }

    .padding-left-#{$i}px {
        padding-left: #{$i}px;
    }

    .padding-right-#{$i}px {
        padding-right: #{$i}px;
    }

    .margin-bottom-#{$i}px {
        margin-bottom: #{$i}px;
    }

    .margin-top-#{$i}px {
        margin-top: #{$i}px;
    }

    .margin-left-#{$i}px {
        margin-left: #{$i}px;
    }

    .margin-right-#{$i}px {
        margin-right: #{$i}px;
    }
}
