.uib-yearpicker{
    
    .btn{
        background: #eaeaea;
        color: black;
    }
}

.uib-monthpicker{
    
    .btn{
        background: #eaeaea;
        color: black;
    }
}

.uib-daypicker{
    .btn {
        background: #fff;
        color: black;
    }

    th {
        .btn {
            background: #efefef;
            color: black;
            height: 30px;
        }
    }
}