body {
    background: $body-bg;

    &.app {
        background: $brand-secondary;
    }
}

.app-content {
    background: #fff;
    min-height: 400px;
    border-bottom: 15px solid $brand-primary;
    box-shadow: 0 5px 10px rgba(#000, 0.2);
}

.admin-content{
    max-width: 100% - $side-bar-width;
    margin-left: $side-bar-width;
    padding-top: $brand-bar-height;

    .container{
        max-width: 100%;
    }
}

.footer {
    padding-top: 1em;
    padding-bottom: 2em;

    p {
        text-align: center;
        font-size: 1em;
        color: #fff;
        margin-bottom: .25em;
    }

    a.terms-conditions{
        cursor: pointer;
        &:hover{
            color: lightgray;
        }
    }
}

.keep-text {
    white-space: nowrap;
}


.caption-body {
    background: #fff;
    border: 0;
    display: block;
    margin: 1em 0;
}

.caption-input {
    resize: none;
}

.toolbar {
    padding: 1em 0;
    min-height: $navbar-height + 20
}

.upload-photo-container {
    width: 100px;
    height: 100px;
    position: relative;
    margin-bottom: .5em;

    &.ghost {
        background: #e7e7e7;
        border: 1px dashed #ccc;
    }

    .remove-button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;
        padding: 0.25em;
        border-radius: 0 0 0 7px;
        cursor:pointer; 
    }
}

.upload-rec-photo-container {
    width: 100px;
    height: 100px;
    position: relative;
    margin-bottom: .5em;
    display: inline-block;

    &.ghost {
        background: #e7e7e7;
        border: 1px dashed #ccc;
    }

    .remove-button {
        display: block;
        position: absolute;
        top: -1px;
        right: -2px;
        background: #fff;
        padding: 0.25em;
        border-radius: 0 0 0 7px;
        cursor:pointer; 
    }
}

.upload-logo-container {
    width: 90%;
    height: 100px;
    position: relative;
    margin-bottom: .5em;
    display: inline-block;

    &.ghost {
        background: #e7e7e7;
        border: 1px dashed #ccc;
    }

    .remove-button {
        display: block;
        position: absolute;
        top: -1px;
        right: -2px;
        background: #fff;
        padding: 0.25em;
        border-radius: 0 0 0 7px;
        cursor:pointer; 
    }
}

.upload-rec-photo-description {
    padding: 1em;
    resize:none;
}

.header-logo {
    cursor: pointer;
    margin-top: -6px;
    max-height: 90px;
}

.account-address {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border:none;
    background-color: transparent;
    padding:0;
}