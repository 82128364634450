h1 {
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    padding-bottom: .25em;
    color: #333;

    @media (min-width: $screen-md-min) {
        font-size: 1.75em;
    }
}

p {
    font-size: 1em;
    letter-spacing: .5px;
    margin-bottom: 1.5em;

    a {
        text-decoration: none !important;
        border-bottom: dotted 1px $brand-primary;
        color: $brand-primary;

        &:hover {
            border-bottom: solid 1px $brand-secondary;
            color: $brand-secondary;
        }
    }

    @media (min-width: $screen-md-min) {
        font-size: 1.25em;
    }
}
