.visits {
    .table {
        .header-title {
            min-width: 120px;
        }

        .header-reference_no {
            width: 120px;
            min-width: 120px;
        }

        .header-due_date {
            width: 200px;
            min-width: 200px;
        }

        .header-description,
        .body-description {
            width: 50%;
            min-width: 300px;
        }

        .body-description {
            white-space: pre-line;
        }

        .body-reference_number {
            width: 145px;
        }

        td{
            min-width: 200px;
            max-width: 200px;
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

