@import
    // base
         'variables'
    ,    'colors'
    ,   'branding'

    // vendors
    ,   'bootstrap'
    ,   'font-awesome'
    ,   'vendors/ng-sortable'
    ,   'buttons'

    // App
    ,   'typography'
    ,   'utils'
    ,   'errors'
    ,   'widgets'
    ,   'modals'
    ,   'tables'
    ,   'toast'
    ,   'spinner'

    ,   'base'
    ,   'nav'
    ,   'pages/pages'
    ,   "adminMenu"
    ,   'edit-controls'
    ,   'datetimepicker'
    ;



$client-dark-blue: #00567C;
$client-light-blue: #00567C;
$client-red: #71B1C8;

// .row {
//     .branding {
        
//     }
// }

.brand-bar {
    img {
        max-height: 70px;
        width: auto !important;
    }

    border-color: $client-dark-blue;
}

.navbar-nav,
.navbar.navbar-default {
    background-color: $client-light-blue;

    li a {
        border-color: $client-red;

        &:hover {
            border-color: #fff;
        }
    }
}

.navbar .navbar-nav li a.active {
    background: $client-red;
    border-color: $client-red;
}

body.app {
    background: #f8f8f8;
}

.footer {
    &, p {
        color: #333;
    }
}

.header-complete_by {
    min-width: 120px;
}

.header-date_completed {
    min-width: 120px;
}

.filter-header {
    white-space: nowrap;
}

.header-recommendation {
    width: 40%;
}

.comment-button {
    margin-top: 1em;
    margin-bottom: 2em;
}

.rec-form {
    .rec-comment {
        background: #efefef;
        border-radius: 5px;
        padding: 1em;
        height: auto;
        color: #000;
        // width: 95%;
        // margin-right: 5%;
        margin-bottom: 1em;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    .rec-comment-listing{
        overflow-y: auto;
        max-height: 300px;
    }
    
    .rec-comment-inner-listing {
        margin-right: 1em;
    }
}

.author-name,
.comment-date {
    font-weight: bold;
    color: #333;
}

.comment-date {
    float: right;
}

.user-comment {
    .rec-comment {
        background: #f7fdff;
        // margin-right: 0;
        // margin-left: 5%;
    }
}

.caption-updated {
    text-align: center;
    margin-top: 1em;
}

.greenCheck {
    color: green;
}

.required {
    &:after {
        color: $client-red;
        content: ' *';
        font-size: 1.25em;
    }
}

.logout-button {
    margin-right:-1em !important;
    background: #fff;
    color: #333;
    border-color: #999;

    &:hover {
        background: #eee;
        color: #333;
    }
}

.asset-button {
    max-width: 200px;
}

.login-page-text {
    &.forget-password-text {
        color: white;
        text-align: center;
        margin-bottom: 2em;
    }
    &.invitation-text {
        color: white;
        text-align: center;
        margin-bottom: 2em;
        margin-top: 1em;        
    }    
}

.invite-input {
    margin-top: 0.5em;
}

.captions-table-name {
    width: 200px;
}

.admin-sub-item{
    padding-left: 3em !important;
    font-size: 12px;
}