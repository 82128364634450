.admin {
    &.brand-bar {
        .logo {
            position: relative;
            display: inline-block;

            .btn-ctrl {
                opacity: 0;
                position: absolute;
                top: 0;
                right: 16px;
                transition: opacity 200ms, top 300ms;
            }
            
            &:hover {
                .btn-ctrl {
                    opacity: 1;
                    top: 5px;                
                }
            }
        }
    }
}