.admin-menu {
    border: 0 !important;

    .logo {
        display: inline-block;
    }

    .wrapper {
        ul {
            li {
                 a {
                    width: 130%;
                    display: block !important;
                    color: #fff;
                    text-align: left !important;
                    padding: 1em;
                    margin-left: -1em;
                    text-decoration: none;

                    &.active {
                        background: #fff;
                        color: #333;
                    }
                }
            }    
        }
    }
}