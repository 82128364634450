.report_bar {
    margin: 2em;
    background: white;
    padding: 1em;
    box-shadow: 3px 3px 5px #888;
    border-radius: 10px;
    border: solid #bbbbbb;
    border-width: thin;

    &.mid {
        max-width: 50%;
        text-align: center;
        margin: 0 auto;        

        canvas{
            max-width: 100%;
        }
    }
}

.dateSelector {
    height: 35px;
    margin-left: 2em; 
}

.commentClicker {
    cursor: pointer;
    width: 50%;
}

.userLoginLogs {
    cursor: pointer;
}

.dateSelectorRow {
    margin-bottom: 1em;

    &.commentFilterRow{
        margin-left: 25%;
        margin-right: 25%
    }
    &.assetFilterRow{
        margin-left: 12%;
        margin-right: 12%
    }
    &.loginFilterRow{
        margin-left: 12%;
        margin-right: 12%
    }    
}
