.message-box {
    background: red;
    padding: 2em;

    &.error {
        background: nth($red, 1);
        border: 1px solid nth($red, 2);
    }
    
    &.success{
        background: nth($green, 1);
        border: 1px solid nth($green, 2);
    }

    &.change-password {
        margin: 0.5em;
    }
}

input[type="text"],
textarea {
    &.ng-dirty {
        &.ng-invalid {
            border: 1px solid nth($red, 2);
        }
    }
}

