$el-font-path: "../fonts/elusive";


// Brand colors
$brand-default: #808080;
$brand-primary: #333; // #084c8a;
$brand-secondary: #fff; // #08b2d4;
$brand-danger: nth($red, 5) !default;
$brand-success: nth($green, 5) !default;

//-------------------------
// Data Grid
//-------------------------
$datagrid-border-color: nth($grey, 2);
$datagrid-title-background-color: lighten(nth($grey, 1), 5%);

$btn-default-bg: nth($grey, 1);
$btn-default-border: nth($grey, 2);

$btn-primary-bg: $brand-secondary;
$btn-primary-border: #54acff;
$btn-primary-color: #333;

$dropdown-border: nth($grey, 2);
$btn-border-radius-base: 25px;

$logo-padding: .5em;

// buttons
$btn-default-bg: $brand-default;
$btn-default-border: darken($brand-default, 5);
$btn-default-color: #fff;

// Body
$body-bg: #eee;
$font-size-base: 16px;

// navbar
$navbar-default-bg: $brand-secondary;
$navbar-default-color: #fff;
$navbar-default-border: #fff;
$navbar-border-radius: 0;
$navbar-height: 70px;
$brand-bar-height:90px;


//sidebar
$side-bar-width: 15%;

// navbar links
$navbar-default-link-color: #fff;
$navbar-default-link-hover-color:          #fff;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         #555 !default;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

// Login form
$login-box-shadow: 0 5px 10px rgba(#000, 0.2);
$login-top-margin: 5em;
$login-top-margin-alt: 25vh;
$login-bg: $brand-secondary;
$login-border: 1px solid #AFAFAF;
$login-border-radius: 0;
$login-branding-padding: 0 1em 1em;
$login-branding-bg: #fff;
$login-branding-color: #808080;
$login-branding-bottom-border: 15px solid #71B1C8;

.navbar-default {
    
}

.login-wallpaper {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: .5;
    background: #fff no-repeat center center url("/images/summit-only.jpg");
    background-size: 100% ; 
}