.tag-container{
    border: 1px solid black;
    padding: 10px;
    height: 300px;
    overflow: auto;
    margin-bottom: 1em
}

.tag-container-text {
    text-align: center;
}

.tag-selector {
    width:50%;
    margin: 0 auto;
}

.tag {
    margin:5px;
}

.thumb {
    display: block;
    width: 100%;
    height: 100px;

    &.thumb-icon{
        font-size: 4em;
        padding-top: .25em;
        text-align: center;
    }
}
