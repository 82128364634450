.communications {
    margin-bottom: 3em;
    border: #b1aeae;
    border-style: solid;
    border-width: 1px; 
    border-radius: 10px;
       
}

.communications-row {
    margin: 1em 0 0 1em;
}

.communications-attachments {
    display: inline-block;
    position: relative;
    text-align: center;
    float: left;
    margin: 0.5em 2em 2em 2em;
}

.comment-attachments {
    float: left;
    margin:0 0.5em;
}

.communications-attachments-font {
    font-size: 14px;
}

.comment-attachments-font {
    font-size: 14px;
    cursor: pointer
}

.communications-attachments-icon {
    font-size: 30px;
}

.communications-title {
    padding-bottom: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.popover {
    max-width: none;
}